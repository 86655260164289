(function ($) {
  $(window).load(function(){
    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,
      
      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: false,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: false,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: false,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        360: {
          stagePadding: 45
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 4
        }
      }
    });
  });

  $(document).ready(function(){
    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }
    
    // navbar-toggler 
    //$(".header-main .navbar-toggler").click(function(){
    //  $("body").toggleClass("navbar-overlay-active");
    //});
    $(document).click(function(event) {
        $(".header-main .navbar-collapse").collapse("hide");
    });

    $('a[data-fancybox]').fancybox({
      buttons: [
          //"zoom",
          //"share",
          //"slideShow",
          //"fullScreen",
          //"download",
          "thumbs",
          "close"
      ]
    });
  });
})(jQuery);